import { template as template_87acfa839e8141748f59a497d91c1467 } from "@ember/template-compiler";
const SidebarSectionMessage = template_87acfa839e8141748f59a497d91c1467(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
