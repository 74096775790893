import { template as template_52abe50ddd774d8293b76f6890752287 } from "@ember/template-compiler";
const FKLabel = template_52abe50ddd774d8293b76f6890752287(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
